import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getLocalizedName } from '../i18n';
import { motion } from 'framer-motion';


function UpcomingSeasonItem({ season }) {
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(season.startDate));
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(season.startDate));
      }, 1000);
  
      return () => clearInterval(timer);
    }, [season.startDate]);
  
    function calculateTimeLeft(startDate) {
      const difference = +new Date(startDate) - +new Date();
      let timeLeft = {};
  
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }
  
      return timeLeft;
    }
  
    function padNumber(num) {
      return num.toString().padStart(2, '0');
    }
  
    return (
      <motion.div 
        className="text-sm mb-2 grid grid-cols-3 gap-2 items-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center">
          <img 
            src={`data:image/png;base64,${season.iconBase64}`} 
            alt={season.gameName} 
            className="w-4 h-4 mr-2 inline-block"
          />
          <span className="truncate">{season.gameName}</span>
        </div>
        <span className="truncate">{season.localizedName}</span>
        <div className="font-mono text-blue-400">
          {timeLeft.days > 0 && `${padNumber(timeLeft.days)}${t('d')} `}
          {padNumber(timeLeft.hours)}:{padNumber(timeLeft.minutes)}:{padNumber(timeLeft.seconds)}
        </div>
      </motion.div>
    );
  }
  
  export default UpcomingSeasonItem;
  