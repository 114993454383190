import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AdminPage() {
  const [games, setGames] = useState([]);
  const [newGame, setNewGame] = useState({
    name: {
      en: '',
      ko: ''
    },
    iconBase64: '',
    seasons: [{
      name: '',
      startDate: '',
      endDate: '',
      loginStartDate: ''
    }]
  });

  useEffect(() => {
    axios.get('/api/games')
      .then(response => setGames(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('name_')) {
      setNewGame(prev => ({
        ...prev,
        name: {
          ...prev.name,
          [name.split('_')[1]]: value
        }
      }));
    } else if (name.startsWith('season_')) {
      const [_, field] = name.split('_');
      setNewGame(prev => ({
        ...prev,
        seasons: [
          {
            ...prev.seasons[0],
            [field]: value
          }
        ]
      }));
    } else {
      setNewGame(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewGame(prev => ({ ...prev, iconBase64: reader.result.split(',')[1] }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddGame = () => {
    axios.post('/api/games', newGame)
      .then(response => {
        setGames([...games, response.data]);
        setNewGame({
          name: { en: '', ko: '' },
          iconBase64: '',
          seasons: [{
            name: '',
            startDate: '',
            endDate: '',
            loginStartDate: ''
          }]
        });
      })
      .catch(error => console.error(error));
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">관리자 페이지</h1>
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">신규 게임 추가</h2>
        <div className="space-y-4">
          <input type="text" name="name_en" placeholder="게임 이름 (영어)" value={newGame.name.en} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input type="text" name="name_ko" placeholder="게임 이름 (한국어)" value={newGame.name.ko} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input type="file" name="icon" onChange={handleFileChange} accept="image/*" className="w-full p-2 border rounded" />
          <input type="text" name="season_name" placeholder="시즌 이름" value={newGame.seasons[0].name} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input type="datetime-local" name="season_startDate" placeholder="시즌 시작일" value={newGame.seasons[0].startDate} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input type="datetime-local" name="season_endDate" placeholder="시즌 종료일" value={newGame.seasons[0].endDate} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input type="datetime-local" name="season_loginStartDate" placeholder="로그인 시작일" value={newGame.seasons[0].loginStartDate} onChange={handleInputChange} className="w-full p-2 border rounded" />
          <input 
            type="text" 
            name="steamGameHubUrl" 
            placeholder="Steam Game Hub URL" 
            value={newGame.steamGameHubUrl} 
            onChange={handleInputChange} 
            className="w-full p-2 border rounded" 
          />
          <button onClick={handleAddGame} className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors duration-300">추가</button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">게임 목록</h2>
        <ul className="space-y-2">
          {games.map(game => (
            <li key={game._id} className="flex items-center p-2 hover:bg-gray-100 rounded">
              <img src={`data:image/png;base64,${game.iconBase64}`} alt={game.name.en} className="w-8 h-8 mr-2" />
              <span className="flex-grow">{game.name.ko} ({game.name.en})</span>
              {game.latestSeason && (
                <span className="text-sm text-gray-600">
                  {new Date(game.latestSeason.startDate).toLocaleDateString()} - {new Date(game.latestSeason.endDate).toLocaleDateString()}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdminPage;