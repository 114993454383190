import React from 'react';
import { motion } from 'framer-motion';

function StreamCard({ stream, toggleFavorite }) {
  const getViewerCountColor = (count) => {
    if (count >= 4000) return 'text-green-400';
    if (count >= 1000) return 'text-yellow-400';
    return 'text-red-400';
  };

  const platformBackgroundColor = stream.platform === 'twitch' 
    ? 'bg-purple-900' 
    : 'bg-green-900';

  return (
    <motion.div 
      className={`rounded-xl shadow-lg overflow-hidden ${platformBackgroundColor}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <a 
        href={stream.platform === 'twitch' 
          ? `https://www.twitch.tv/${stream.broadcaster.login}` 
          : `https://chzzk.naver.com/live/${stream.broadcaster.id}`}
        target="_blank" 
        rel="noopener noreferrer"
        className="block"
      >
        {stream.previewImageURL ? (
          <img 
            src={stream.previewImageURL} 
            alt={stream.title} 
            className="w-full h-48 object-cover" 
          />
        ) : (
          <div className="w-full h-48 bg-black flex items-center justify-center">
            <span className="text-white text-center">Preview is not available</span>
          </div>
        )}
      </a>
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            {stream.broadcaster.profileImageURL && (
              <img 
                src={stream.broadcaster.profileImageURL} 
                alt={stream.broadcaster.displayName} 
                className="w-8 h-8 rounded-full mr-2"
              />
            )}
            <a 
              href={stream.platform === 'twitch' 
                ? `https://www.twitch.tv/${stream.broadcaster.login}` 
                : `https://chzzk.naver.com/live/${stream.broadcaster.id}`}
              target="_blank" 
              rel="noopener noreferrer"
              className="font-bold hover:text-blue-400 transition-colors"
            >
              {stream.broadcaster.displayName}
            </a>
          </div>
          <button 
            onClick={() => toggleFavorite(`${stream.platform}-${stream.id}`)}
            className="text-yellow-400 hover:text-yellow-300 transition-colors"
          >
            {stream.isFavorite ? '★' : '☆'}
          </button>
        </div>
        <h3 className="text-lg font-semibold mb-2 truncate">
          <a 
            href={stream.platform === 'twitch' 
              ? `https://www.twitch.tv/${stream.broadcaster.login}` 
              : `https://chzzk.naver.com/live/${stream.broadcaster.id}`}
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:text-blue-400 transition-colors"
          >
            {stream.title}
          </a>
        </h3>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-400">
            {stream.platform === 'twitch' ? stream.game.name : stream.chzzk_liveCategoryValue}
          </span>
          <span className={`text-sm font-bold ${getViewerCountColor(stream.viewersCount)}`}>
            {stream.viewersCount.toLocaleString()} viewers
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default StreamCard;