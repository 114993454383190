import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getLocalizedName } from '../i18n';
import { motion } from 'framer-motion';
import StreamCard from './StreamCard';
import CountdownTimer from './CountdownTimer';
import UpcomingSeasonItem from './UpcomingSeasonItem';
import Cookies from 'js-cookie';

// renderDateReference 함수를 컴포넌트 외부로 이동
const renderDateReference = (date, dateRef, t) => {
  if (dateRef) {
    return (
      <a href={dateRef} target="_blank" rel="noopener noreferrer" className="ml-2 text-blue-400 hover:text-blue-300">
        (?)
      </a>
    );
  } else {
    return (
      <span className="ml-2 text-red-500 cursor-help" title={t('unofficialDate')}>
        (!)
      </span>
    );
  }
};

function GameList() {
  const [games, setGames] = useState([]);
  const [upcomingSeasonsSoon, setUpcomingSeasonsSoon] = useState([]);
  const [upcomingSeasonsLater, setUpcomingSeasonsLater] = useState([]);
  const [isGamesLoading, setIsGamesLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState('');
  const { t, i18n } = useTranslation();
  const USE_STATIC_DATA = process.env.CF_PAGES === '1' || process.env.REACT_APP_STATIC_RUN === '1';
  const [currentTime, setCurrentTime] = useState(new Date());
  const [streams, setStreams] = useState([]);
  const [favoriteStreams, setFavoriteStreams] = useState([]);
  const [isStreamsLoading, setIsStreamsLoading] = useState(true);
  const [lastReloadTime, setLastReloadTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatCurrentTime = () => {
    return currentTime.toLocaleString(i18n.language, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  const fetchStreams = useCallback(async () => {
    setIsStreamsLoading(true);
    setLastReloadTime(Date.now());
    try {
      let gameNames = games.map(game => game.name.en).join(',') + ',Diablo IV Vessel of Hatred';
      
      const response = await axios.get(`/streams?game=${encodeURIComponent(gameNames)}`);
      const allStreams = response.data;

      const twitchStreams = allStreams.filter(stream => stream.platform === 'twitch');
      const chzzkStreams = allStreams.filter(stream => stream.platform === 'chzzk');

      const selectedTwitchStreams = twitchStreams.slice(0, 9);
      const selectedChzzkStreams = chzzkStreams.slice(0, 9);

      const finalStreams = [
        ...selectedTwitchStreams,
        ...selectedChzzkStreams,
      ].sort((a, b) => b.viewersCount - a.viewersCount);

      const favStreams = Cookies.get('favoriteStreams');
      const favoriteStreamIds = favStreams ? JSON.parse(favStreams) : [];

      const streamsWithFavorite = finalStreams.map(stream => ({
        ...stream,
        isFavorite: favoriteStreamIds.includes(`${stream.platform}-${stream.id}`)
      }));

      setStreams(streamsWithFavorite);
      setFavoriteStreams(streamsWithFavorite.filter(stream => stream.isFavorite));
    } catch (error) {
      console.error('Error fetching streams:', error);
    } finally {
      setIsStreamsLoading(false);
    }
  }, [games]);

  useEffect(() => {
    if (games.length > 0) {
      fetchStreams();
    }
  }, [games, fetchStreams]);

  const toggleFavorite = useCallback((streamId) => {
    const updatedStreams = streams.map(stream => {
      if (`${stream.platform}-${stream.id}` === streamId) {
        return { ...stream, isFavorite: !stream.isFavorite };
      }
      return stream;
    });

    setStreams(updatedStreams);
    const newFavorites = updatedStreams.filter(stream => stream.isFavorite);
    setFavoriteStreams(newFavorites);

    const favoriteStreamIds = newFavorites.map(stream => `${stream.platform}-${stream.id}`);
    Cookies.set('favoriteStreams', JSON.stringify(favoriteStreamIds));
  }, [streams]);

  useEffect(() => {
    const fetchGames = async () => {
      setIsGamesLoading(true);
      setError(null);
      try {
        let gamesData;
        if (USE_STATIC_DATA) {
          const response = await fetch('/games_data.json');
          gamesData = await response.json();
        } else {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/games`);
          gamesData = response.data;
        }
        const sortedGames = gamesData.map(game => ({
          ...game,
          localizedName: getLocalizedName(game.name, i18n.language),
          upcomingSeasons: game.upcomingSeasons.map(season => ({
            ...season,
            localizedName: getLocalizedName(season.name, i18n.language)
          })),
          currentSeason: game.currentSeason ? {
            ...game.currentSeason,
            localizedName: getLocalizedName(game.currentSeason.name, i18n.language)
          } : null
        })).sort((a, b) => b.currentPlayers - a.currentPlayers);
        
        setGames(sortedGames);

        const allUpcomingSeasons = sortedGames.flatMap(game => 
          game.upcomingSeasons.map(season => ({
            ...season, 
            gameName: game.localizedName,
            iconBase64: game.iconBase64
          }))
        );

        const now = new Date();
        const tenDaysLater = new Date(now.getTime() + 10 * 24 * 60 * 60 * 1000);

        const soonSeasons = allUpcomingSeasons.filter(season => {
          const startDate = new Date(season.startDate);
          return startDate > now && startDate <= tenDaysLater;
        }).sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

        const laterSeasons = allUpcomingSeasons.filter(season => {
          const startDate = new Date(season.startDate);
          return startDate > tenDaysLater;
        }).sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

        setUpcomingSeasonsSoon(soonSeasons);
        setUpcomingSeasonsLater(laterSeasons);
      } catch (error) {
        console.error(error);
        setError("Something wrong. Nothing to show :(");
      } finally {
        setIsGamesLoading(false);
      }
    };

    fetchGames();
  }, [i18n.language]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (upcomingSeasonsSoon.length > 0) {
        const nextSeason = upcomingSeasonsSoon[0];
        const timeLeft = new Date(nextSeason.startDate) - new Date();
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        setCountdown(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [upcomingSeasonsSoon]);

  const handleVote = (gameId, seasonId, voteType) => {
    console.log(`${voteType} for game ${gameId}, season ${seasonId}`);
    // TODO: Implement voting logic
  };

  const formatTimeLeft = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    if (days > 0) {
      return `${days}${t('days')} ${hours}${t('hours')}`;
    } else {
      return `${hours}${t('hours')} ${minutes}${t('minutes')} ${seconds}${t('seconds')}`;
    }
  };

  const formatTimePassed = (startDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const diff = now - start;
    
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return `${days}${t('days')}`;
  };

  const calculateUserChange = (latestUserCount) => {
    // TODO: Implement user change calculation logic
    return 0; // Placeholder
  };

  const getGameUrl = (game) => {
    const currentLang = i18n.language;
    if (game.gameUrlLocalized && game.gameUrlLocalized[currentLang]) {
      return game.gameUrlLocalized[currentLang];
    } else if (game.gameUrl) {
      return game.gameUrl;
    } else {
      return game.steamGameHubUrl;
    }
  };

  const renderPlayerCount = (game) => {
    if (!game.steamGameHubUrl || game.steamGameHubUrl.trim() === '') {
      return (
        <div className="text-3xl font-bold text-gray-400 flex items-center cursor-help" 
             title={t('steamOnlyMessage')}>
          <span className="mr-2">👤</span>
          (?)
        </div>
      );
    } else {
      return (
        <div className="text-3xl font-bold text-green-400 flex items-center">
          <span className="mr-2">👤</span>
          {game.currentPlayers.toLocaleString()}
        </div>
      );
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
      },
    }),
  };

  const handleReloadStreams = () => {
    const currentTime = Date.now();
    if (currentTime - lastReloadTime >= 60000) { // 1분 = 60000 밀리초
      fetchStreams();
    }
  };

  const getReloadButtonText = () => {
    if (isStreamsLoading) {
      return 'Loading...';
    }
    const timeLeft = Math.max(0, 60 - Math.floor((Date.now() - lastReloadTime) / 1000));
    if (timeLeft > 0) {
      return `Reload in ${timeLeft.toString().padStart(2, '0')}s`;
    }
    return 'Reload Streams';
  };

  const isReloadDisabled = isStreamsLoading || Date.now() - lastReloadTime < 60000;

  if (isGamesLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <p className="text-xl">{error}</p>
      </div>
    );
  }

  if (games.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <p className="text-xl">Something wrong. Nothing to show :(</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-900 text-white">
      <div className="absolute top-0 right-0 p-4 text-gray-400 bg-gray-800 bg-opacity-50 rounded-bl-lg text-sm">
        {formatCurrentTime()}
      </div>

      {/* Favorite Streams */}
      {favoriteStreams.length > 0 && (
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Favorite Streams</h2>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.1 } }
            }}
          >
            {favoriteStreams.map((stream) => (
              <StreamCard 
                key={`${stream.platform}-${stream.id}`} 
                stream={stream} 
                toggleFavorite={toggleFavorite}
              />
            ))}
          </motion.div>
        </div>
      )}


      {upcomingSeasonsSoon.length > 0 && (
        <motion.div 
          className="mb-8 space-y-4"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.1 } }
          }}
        >
          {upcomingSeasonsSoon.slice(0, 1).map((season, index) => (
            <motion.div 
              key={index} 
              className="bg-gradient-to-r from-blue-900 to-purple-900 rounded-xl shadow-lg overflow-hidden"
              variants={cardVariants}
            >
              <div className="p-8 text-center">
                <div className="flex flex-col items-center">
                  <img src={`data:image/png;base64,${season.iconBase64}`} alt={season.gameName} className="w-24 h-24 mb-4" />
                  <p className="text-2xl mb-2">{season.localizedName} 
                  {renderDateReference(season.startDate, season.startDateRef, t)}
                  </p>
                  <div className="text-4xl font-bold mb-4">
                    <CountdownTimer startDate={season.startDate} startDateRef={season.startDateRef} />
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      )}

      {upcomingSeasonsLater.length > 0 && (
        <motion.div 
          className="mb-8"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.1 } }
          }}
        >
          <div className="bg-gray-800 rounded-xl p-4">
            {upcomingSeasonsLater.slice(0, 3).map((season, index) => (
              <UpcomingSeasonItem key={index} season={season} />
            ))}
          </div>
        </motion.div>
      )}

      {/* 게임 카드 리스트 */}
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8"
        initial="hidden"
        animate="visible"
        variants={{
          visible: { transition: { staggerChildren: 0.1 } }
        }}
      >
        {games.map((game, index) => (
          <motion.div 
            key={game._id} 
            className="bg-gray-800 rounded-xl shadow-lg overflow-hidden"
            variants={cardVariants}
            custom={index}
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <img src={`data:image/png;base64,${game.iconBase64}`} alt={game.localizedName} className="w-8 h-8 mr-2" />
                  <a href={getGameUrl(game)} target="_blank" rel="noopener noreferrer" className="text-2xl font-semibold hover:text-blue-400">
                    {game.localizedName}
                  </a>
                </div>
                {renderPlayerCount(game)}
              </div>
              {game.currentSeason ? (
                <>
                  <h3 className="text-xl font-semibold mb-2">{game.currentSeason.localizedName}</h3>
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex space-x-2">
                      <button onClick={() => handleVote(game._id, game.currentSeason._id, 'upvote')} className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-full">👍 {game.currentSeason.upvotes}</button>
                      <button onClick={() => handleVote(game._id, game.currentSeason._id, 'downvote')} className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-full">👎 {game.currentSeason.downvotes}</button>
                    </div>
                    <div className="text-right">
                      <span className="text-sm font-medium text-gray-400 mr-2">{formatTimePassed(game.currentSeason.startDate)} {t('passed')}</span>
                      <span className="text-sm font-medium text-blue-400">{formatTimeLeft(game.currentSeason.endDate)} {t('left')}</span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="w-full bg-gray-700 rounded-full h-2">
                      <div className="bg-blue-500 h-2 rounded-full" style={{width: `${(new Date() - new Date(game.currentSeason.startDate)) / (new Date(game.currentSeason.endDate) - new Date(game.currentSeason.startDate)) * 100}%`}}></div>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400">
                    <p>
                      {t('seasonStart')}: {new Date(game.currentSeason.startDate).toLocaleString()}
                      {renderDateReference(game.currentSeason.startDate, game.currentSeason.startDateRef, t)}
                    </p>
                    <p>
                      {t('seasonEnd')}: {new Date(game.currentSeason.endDate).toLocaleString()}
                      {renderDateReference(game.currentSeason.endDate, game.currentSeason.endDateRef, t)}
                    </p>
                    {game.currentSeason.loginStartDate && (
                      <p>{t('loginStart')}: {new Date(game.currentSeason.loginStartDate).toLocaleString()}</p>
                    )}
                  </div>
                </>
              ) : (
                <p className="text-gray-400">{t('noCurrentSeason')}</p>
              )}
              {game.upcomingSeasons && game.upcomingSeasons.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-2">{t('upcomingSeasons')}</h4>
                  <ul className="space-y-2">
                    {game.upcomingSeasons.map(season => (
                      <li key={season._id} className="text-sm text-gray-400">
                        {season.localizedName}: {new Date(season.startDate).toLocaleString()} - {new Date(season.endDate).toLocaleString()}
                        {renderDateReference(season.startDate, season.startDateRef, t)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <a //href={`/games/${game._id}/history`} 
              className="block bg-gray-700 text-center py-2 text-blue-400 hover:bg-gray-600 transition-colors duration-300">
                {t('pastSeasonInfo')}
            </a>
          </motion.div>
        ))}
      </motion.div>

      {/* Live Streams */}
      <div className="mt-12">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Live Streams</h2>
          <button 
            onClick={handleReloadStreams}
            className={`px-4 py-2 rounded font-mono text-sm w-40 transition-colors ${
              isReloadDisabled
                ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
            disabled={isReloadDisabled}
          >
            {getReloadButtonText()}
          </button>
        </div>
        {isStreamsLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.1 } }
            }}
          >
            {streams.map((stream) => (
              <StreamCard 
                key={`${stream.platform}-${stream.id}`} 
                stream={stream} 
                toggleFavorite={toggleFavorite}
              />
            ))}
          </motion.div>
        )}
      </div>


      <div className="mt-8 text-center text-gray-500 text-sm">
      User player count data is <a href="http://steampowered.com" className="text-blue-400 hover:underline">powered by Steam</a>. Not affiliated with Valve in any way. 
      All trademarks are property of their respective owners in the US and other countries.
    </div>
    </div>
  );
}

export default GameList;