import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "popularGameSeasonTracker": "NextGame: Season Tracker",
      "daysLeft": "days left",
      "seasonStart": "Season Start",
      "seasonEnd": "Season End",
      "pastSeasonInfo": "Past Season Info",
      "admin": "Admin",
      "language": "Language",
      "days": "Days",
      "hours": "h",
      "minutes": "m",
      "seconds": "s",
      "passed": "passed",
      "left": "left",
      "upcomingSeasonCountdown": "Upcoming Season Countdown",
      "unofficialDate": "This date is unofficial and based on speculation.",
      "currentTime": "Current Time",
      "steamOnlyMessage": "User count are only available for Steam published games",
    }
  },
  ko: {
    translation: {
      "popularGameSeasonTracker": "NextGame: 폐지겜 시즌 트래커",
      "daysLeft": "일 남음",
      "seasonStart": "시즌 시작",
      "seasonEnd": "시즌 종료",
      "pastSeasonInfo": "지난 시즌 정보",
      "admin": "관리자",
      "language": "언어",
      "days": "일",
      "hours": "시간",
      "minutes": "분",
      "seconds": "초",
      "passed": "지남",
      "left": "남음",
      "upcomingSeasonCountdown": "다가오는 시즌 카운트다운",
      "unofficialDate": "이 날짜는 오피셜이 아니라 뇌피셜입니다.",
      "currentTime": "현재 시간",
      "steamOnlyMessage": "사용자 수는 Steam에 출시된 게임에서만 확인 가능합니다",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko",
    interpolation: {
      escapeValue: false
    }
  });

export function getLocalizedName(nameObj, language, defaultLanguage = 'en') {
  if (!nameObj) return '';
  if (typeof nameObj === 'string') return nameObj;
  return nameObj[language] || nameObj[defaultLanguage] || Object.values(nameObj)[0] || '';
}

export default i18n;