import React, { useRef, useEffect } from 'react';

const SynthwaveBackground = () => {
  const canvasRef = useRef(null);
  const starsRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let startTime = null;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Generate stars
    const generateStars = () => {
      const starCount = 400; // Adjust as needed
      const stars = [];

      for (let i = 0; i < starCount; i++) {
        const sizeOptions = [1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5];
        const size = sizeOptions[Math.floor(Math.random() * sizeOptions.length)];
        const x = Math.random() * canvas.width * 2;
        const y = Math.random() * (canvas.height * 2); // Upper half of the canvas
        stars.push({ x, y, size });
      }

      starsRef.current = stars;
    };

    generateStars();

    const drawGrid = (time) => {
      if (!startTime) startTime = time;
      const elapsedTime = time - startTime;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      const horizonY = canvas.height * 0.5; // Horizon position
      const centerX = canvas.width * 0.5; // Center X coordinate

      // Background gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, '#000033');
      gradient.addColorStop(1, '#660066');
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw stars
      const stars = starsRef.current;
      const rotationSpeed = 0.00005; // Adjust for rotation speed
      const angle = (elapsedTime * rotationSpeed) % (Math.PI * 2);

      //ctx.save();
      //ctx.translate(centerX, horizonY); // Rotate around the center of the upper half
      //ctx.rotate(angle);

      ctx.fillStyle = '#00FFFF'; // Neon blue color
      ctx.strokeStyle = '#00FFFF';

      stars.forEach((star) => {
        const dx = star.x - centerX;
        const dy = star.y - (horizonY / 2);
        const rotatedX = dx * Math.cos(angle) - dy * Math.sin(angle);
        const rotatedY = dx * Math.sin(angle) + dy * Math.cos(angle);

        const finalX = rotatedX + centerX;
        const finalY = rotatedY + (horizonY / 2);

        if (finalY > canvas.height/2) return;
        if (star.size === 1) {
          // 1-pixel dot
          ctx.fillRect(finalX, finalY, 1, 1);
        } else if (star.size === 5) {
          // 5-pixel cross
          ctx.beginPath();
          ctx.moveTo(finalX - 2, finalY);
          ctx.lineTo(finalX + 2, finalY);
          ctx.moveTo(finalX, finalY - 2);
          ctx.lineTo(finalX, finalY + 2);
          ctx.stroke();
        } else if (star.size === 9) {
          // 9-pixel dot
          ctx.beginPath();
          ctx.arc(finalX, finalY, 4.5, 0, Math.PI * 2);
          ctx.fill();
        }
      });

      //ctx.restore();

      // Vertical lines (3D perspective)
      const verticalCount = 200; // Increased vertical lines
      for (let i = 0; i <= verticalCount; i++) {
        const progress = i / verticalCount;
        const x = centerX + (progress - 0.5) * canvas.width * 32; // Extend beyond screen
        const vanishingPoint = centerX + (progress - 0.5) * canvas.width * 0.1;

        const alpha = 0.0 + 0.5 * (1 - Math.abs(verticalCount / 2 - i) / (verticalCount / 2));
        ctx.strokeStyle = `rgba(255, 0, 255, ${alpha})`;

        ctx.beginPath();
        ctx.moveTo(x, canvas.height);
        ctx.lineTo(vanishingPoint, horizonY);
        ctx.stroke();
      }

      // Horizon
      ctx.strokeStyle = `rgba(255, 0, 255, 0.3)`;
      ctx.beginPath();
      ctx.moveTo(0, horizonY);
      ctx.lineTo(canvas.width, horizonY);
      ctx.stroke();

      // Horizontal lines
      const horizontalCount = 20;
      const speed = 0.0001; // Speed control
      const cycleOffset = (elapsedTime * speed) % 1;

      for (let i = 0; i <= horizontalCount; i++) {
        const baseProgress = i / horizontalCount;
        const progress = (baseProgress + cycleOffset) % 1;
        const y = horizonY + Math.pow(progress, 2) * (canvas.height - horizonY);
        
        const alpha = 0.3 + 0.7 * (progress / 2);

        ctx.strokeStyle = `rgba(255, 0, 255, ${alpha})`;
        
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
        ctx.stroke();
      }

      animationFrameId = requestAnimationFrame(drawGrid);
    };

    drawGrid(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} className="synthwave-background" />;
};

export default SynthwaveBackground;
