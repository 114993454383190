import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getLocalizedName } from '../i18n';
import { motion } from 'framer-motion';

function CountdownTimer({ startDate, startDateRef }) {
    const calculateTimeLeft = useCallback(() => {
      const timeLeft = new Date(startDate) - new Date();
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      return { days, hours, minutes, seconds };
    }, [startDate]);
  
    const [countdown, setCountdown] = useState(calculateTimeLeft());
    const { t } = useTranslation();
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCountdown(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, [calculateTimeLeft]);
  
    return (
      <motion.div 
        className="flex flex-col items-center font-mono"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 120, damping: 10 }}
      >
        {countdown.days > 0 && (
          <span className="text-8xl font-bold mb-2">
            {countdown.days.toString().padStart(0, '0')}{t('days')}
          </span>
        )}
        <div className="flex space-x-2">
          <span className="inline-block bg-opacity-20 bg-white rounded-lg px-4 py-2 w-24 text-center">
            {countdown.hours.toString().padStart(2, '0')}
          </span>
          <span className="inline-block bg-opacity-20 bg-white rounded-lg px-4 py-2 w-24 text-center">
            {countdown.minutes.toString().padStart(2, '0')}
          </span>
          <span className="inline-block bg-opacity-20 bg-white rounded-lg px-4 py-2 w-24 text-center">
            {countdown.seconds.toString().padStart(2, '0')}
          </span>
        </div>
      </motion.div>
    );
  }
  
  export default CountdownTimer;
